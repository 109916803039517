import {createApp, h, provide} from "vue";
import {DefaultApolloClient} from "@vue/apollo-composable";
import App from "./App.vue";
import {apolloClient} from "./providers/graphql.provider";
import router from "./router";
import {AnalyticsProvider} from "@/providers/analytics.provider";
import "@/styles/app.scss";
import { createPinia } from 'pinia';
import 'pinia';
import {createI18n} from "vue-i18n";
import en from "@/localization/en.json";
import de from "@/localization/de.json";
import fr from "@/localization/fr.json";
import es from "@/localization/es.json";
import hr from "@/localization/hr.json";
import pl from "@/localization/pl.json";
import pt from "@/localization/pt.json";
import sl from "@/localization/sl.json";
import sr from "@/localization/sr.json";
import ua from "@/localization/ua.json";

// declare a global variable for Cypress
// Cypress is only available in test mode
// Cypress sets this variable to window and
// because window by default does not have it in ts need to declare it
declare global {
    interface Window {
        Cypress?: unknown;
    }
}


// create analytics provider
const analyticsProvider = new AnalyticsProvider(process.env.VUE_APP_MIXPANEL_KEY || "");

// extend component properties
declare module '@vue/runtime-core' {
    interface ComponentCustomProperties  {
        $analytics: AnalyticsProvider;
    }
}

// extend pinia store properties - add analytics
declare module 'pinia' {
    export interface PiniaCustomProperties {
        $analytics: AnalyticsProvider,
    }
}

// setup pinia store
const pinia = createPinia();
pinia.use(({store}) => {
    store.$analytics = analyticsProvider;
});

// @TODO improve and load only when you know which locale you need
type Locale = typeof en & typeof de & typeof fr & typeof es & typeof hr & typeof pl & typeof pt & typeof sl & typeof sr;
const i18n = createI18n<[Locale], 'en' | 'de' | 'fr' | 'es' | 'hr' | 'pl' | 'pt' | 'sl' | 'sr' | 'ua'>({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en,
        de,
        fr,
        es,
        hr,
        pl,
        pt,
        sl,
        sr,
        ua,
    }
})

const app = createApp({
    setup() {
        provide(DefaultApolloClient, apolloClient);
    },
    render: () => h(App),
});

// add plugins
app.use(pinia);
app.use(i18n)
app.use(router);

// mount the app
app.mount("#app");

// inject analytics provider in global properties
app.config.globalProperties.$analytics = analyticsProvider;
