import { PlaybackDataInterface } from "@/store/player.store";
import { Post } from "@/models/post";
import { Campaign } from "@/models/campaign";

export interface Keyable {
    // eslint-disable-next-line
    [key: string]: any;
}

export enum EmbedType {
    Post = "post",
    PostMini = "post-mini",
    TranscriptPostMini = "post-mini-with-transcript",
    ReaderView = "reader-view",
}

export class AnalyticsProvider {
    private maximumFreetextLength = 30;
    private wasInitialized = false;
    private readonly mixpanelKey: string;

    constructor(mixpanelKey: string) {
        this.mixpanelKey = mixpanelKey;
    }

    initialiseIfNeeded(): boolean {
        if (window.Cypress) {
            return false;
        }

        if (this.wasInitialized) {
            return true;
        }

        console.log("initialising Mixpanel tracking");

        this.wasInitialized = true;

        this.mixpanel.init(this.mixpanelKey, {
            api_host: "https://api-eu.mixpanel.com",
            cross_site_cookie: true,
            debug: true,
            ignore_dnt: true,
            ip: false,
            track_pageview: false,
            cookie_domain: ".beams.fm", // see https://github.com/mixpanel/mixpanel-js/issues/320
        });

        this.mixpanel.register({
            platform: "embed",
        });

        this.mixpanel.track("appOpened", { url: window.location.href });

        return true;
    }

    // !!!! REMOVE linter ignore when start using the function
    // eslint-disable-next-line
    trackPageView(name: string, params?: Keyable) {
        // This is a NO-OP call. We can't track pageViews anymore because we'd like to avoid
        // users being tracked until they interact with the embed. Page View is by definition not an interaction
        //
        // this.initialiseIfNeeded();
        //
        // console.log('tracking page view', name, params);
        //
        // this.window.dataLayer.push({event: 'pageview'});
        // this.mixpanel.track('web_page_view', { url: window.location.href, params: params });
    }

    trackEvent(name: string, embedType?: EmbedType, params?: Keyable) {
        if (!this.initialiseIfNeeded()) {
            return;
        }

        if (!params) {
            params = {};
        }

        params = {
            ...params,
            embedType: embedType,
            referrerURL: document.referrer ? document.referrer : null,
            referrerDomain: document.referrer ? document.referrer.split("/")[2] : null,
        };

        const campaignTracker = this.getQueryParamValue("ct");
        if (campaignTracker) {
            params["campaign"] = campaignTracker;
            this.mixpanel.people.set_once({
                firstReferrerCampaign: campaignTracker,
            });
        }

        console.log("tracking event", ["event", name, params]);
        // this.window.dataLayer.push("event", name, params);
        this.mixpanel.track(name, params);

        if (window && window.parent) {
            window.parent.postMessage({ name: "beamsAnalyticsEvent", event: { name, params } }, "*");
        }

        if (window && window.BeamsSDK && typeof window.BeamsSDK !== "undefined") {
            // Legacy way, remove once new version of iOS SDK is shipped
            if (typeof window.BeamsSDK.analyticsEvent !== "undefined") {
                window.BeamsSDK.analyticsEvent(name, params);
            }

            if (typeof window.BeamsSDK.analyticsSerializedEvent !== "undefined") {
                window.BeamsSDK.analyticsSerializedEvent(name, JSON.stringify(params));
            }
        }
    }

    postParams(post: Post | undefined) {
        if (!post) {
            return {};
        }

        return {
            item_id: post.id,
            item_name: this.shortenedText(post.caption ? post.caption : ""),
            content_url: post.url,
            profile_id: post.profile.id,
            has_photo: this.yesNo(post.image === undefined),
            has_caption: this.yesNo(post.caption === undefined),
        };
    }

    campaignParams(campaign: Campaign | undefined) {
        if (!campaign) {
            return {};
        }

        return {
            campaign_id: campaign.id,
            campaign_prompt: campaign.promptText,
            campaign_url: campaign.contributionURL,
            profile_id: campaign.profile.id,
            profile_url: campaign.profile.url,
            success_text: campaign.successText,
            success_link: campaign.successLink,
            success_link_label: campaign.successLinkLabel,
            enforces_consent: campaign.enforcesTermsAndConditionsConsent,
            conversational_survey: campaign.conversationalSurvey,
            agent_welcome_message: campaign.agentWelcomeMessage,
            is_campaign_with_video: campaign.videoURLs !== undefined && campaign.videoURLs !== null && campaign.videoURLs.length > 0,
        };
    }

    playParams(state: string, post: Post, playbackData: PlaybackDataInterface | null): Keyable {
        const generalParams = {
            item_id: post.id,
            item_name: this.shortenedText(post.caption ? post.caption : ""),
            content_url: post.url,
            profile_id: post.profile.id,
            appInForeground: "not-set",
            playbackSpeed: "1.0",
        };

        const fullParams = (completed: boolean) => {
            if (!playbackData) {
                return {};
            }

            return {
                played: `${Math.round(playbackData.currentTime * 10) / 10}`,
                duration: `${Math.round(playbackData.duration * 10) / 10}`,
                completed: this.yesNo(completed),
            };
        };

        if (state === "began") {
            return generalParams;
        } else if (state === "partial") {
            return { ...generalParams, ...fullParams(false) };
        } else {
            return { ...generalParams, ...fullParams(true) };
        }
    }

    yesNo(booleanValue: boolean) {
        return booleanValue ? "yes" : "no";
    }

    shortenedText(original: string) {
        return original.substr(0, this.maximumFreetextLength);
    }

    private getQueryParamValue(name: string) {
        const params = new URL(document.location.href).searchParams;
        return params.get(name);
    }

    private get window() {
        // eslint-disable-next-line
        return window as any;
    }

    private get mixpanel() {
        return this.window.mixpanel;
    }
}
