import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/:href(.*)",
        name: "recorder",
        component: () => import("@/views/survey/SurveyView.vue"),
    },
    {
        path: "/survey/:href(.*)",
        name: "survey",
        component: () => import("@/views/survey/SurveyView.vue"),
    },
    {
        path: "/",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
    },
    {
        path: "/post",
        name: "post",
        component: () => import("@/views/post/PostView.vue"),
    },
    {
        path: "/post-mini",
        name: "postMini",
        component: () => import("@/views/post/MiniPostView.vue"),
    },
    {
        path: "/reader",
        name: "reader",
        component: () => import("@/views/post/ReaderView.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
