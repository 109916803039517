import { ApolloClient, InMemoryCache } from "@apollo/client/core";

const cache = new InMemoryCache();

if (!process.env.VUE_APP_GRAPHQL_API_URL || !process.env.VUE_APP_GRAPHQL_API_KEY || !process.env.VUE_APP_BUILD_VERSION) {
    console.error("MISSING GRAPHQL variables");
}

export const apolloClient = new ApolloClient({
    uri: process.env.VUE_APP_GRAPHQL_API_URL,
    headers: {
        "x-api-key": process.env.VUE_APP_GRAPHQL_API_KEY || "",
        platform: "web-embed",
        version: process.env.VUE_APP_BUILD_VERSION || "",
    },
    cache,
});
